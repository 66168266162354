import Box from "./Box";
import CapSize from "./CapSize";

function Errors({ errors }) {
  return (
    <Box py={200} px={20}>
      <Box
        mx="auto"
        borderRadius={10}
        px={20}
        py={50}
        maxWidth={500}
        background="black"
      >
        <CapSize textAlign="center" display="block">
          Sorry, the following errors have occured:
        </CapSize>
        <CapSize textAlign="center" display="block" pt={20}>
          {errors.join(", ")}
        </CapSize>
      </Box>
    </Box>
  );
}

export default Errors;
