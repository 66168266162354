import BodyText from "./BodyText";
import BodyTextLarge from "./BodyTextLarge";
import Box from "./Box";
import BulletList from "./BulletList";
import CapSize from "./CapSize";
import LottieInCircle from "./LottieInCircle";

import { useEnteredViewport } from "../core/hooks";

function Card({
  heading,
  text,
  list,
  animData,
  icon,
  transitionDelay,
  number,
  animSize,
  ...props
}) {
  const { ref, entered } = useEnteredViewport(0.5);
  const circleWidth = animSize ? animSize : [126, null, 180, null];
  return (
    <Box ref={ref} {...props}>
      <Box
        height="100%"
        backgroundColor="gulfBlue"
        borderRadius="25px"
        display="flex"
        flexDirection="column"
        p={[28, null, "7%", null]}
        transition="transform 1000ms cubic-bezier(0.16, 1, 0.3, 1), opacity 500ms"
        transitionDelay={transitionDelay}
        transform={entered ? "translate3d(0,0,0)" : "translate3d(0,100px,0)"}
        opacity={entered ? 1 : 0}
      >
        <Box display="flex" justifyContent="flex-end" width="100%">
          {number && (
            <Box flex="1">
              <BodyText color="cyan"></BodyText>
              <CapSize fontSize={18} color="cyan">
                0{number} -
              </CapSize>
            </Box>
          )}
          {animData ? (
            <Box width={circleWidth}>
              <LottieInCircle
                backgroundColor="trailstoneNavy"
                animData={animData}
              />
            </Box>
          ) : (
            <CardIcon iconName={icon} />
          )}
        </Box>

        <Box
          pt={[30, null, 50, null]}
          height="100%"
          display="flex"
          flexDirection="column"
        >
          {heading && (
            <Box mb={22}>
              <BodyTextLarge color="lime">{heading}</BodyTextLarge>
            </Box>
          )}

          {text && <BodyText>{text}</BodyText>}
          {list && <BulletList items={list} />}
        </Box>
      </Box>
    </Box>
  );
}

export default Card;
