import { color, layout, space, typography } from "styled-system";

import styled from "styled-components";

const List = styled.ul`
  ${color}
  ${layout}
  ${space}
`;

const Li = styled.li`
  ${color}
  ${layout}
  ${space}
  ${typography}
  list-style-type: disc;
`;

function BulletList({ items }) {
  return (
    <List pl={18}>
      {items.map((item, index) => {
        return (
          <Li key={index} lineHeight="28px">
            {item}
          </Li>
        );
      })}
    </List>
  );
}

export default BulletList;
